.shaka-hidden {
  display: none !important;
}

.shaka-video-container {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.shaka-video-container .material-icons-round {
  font-family: Material Icons Round;
  font-size: 24px;
}

.shaka-video-container * {
  font-family: Roboto-Regular, Roboto, sans-serif, TengwarTelcontar;
}

.shaka-video-container:-webkit-full-screen {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.shaka-video-container:fullscreen {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.shaka-video-container:fullscreen .shaka-text-container {
  font-size: 4.4vmin;
}

.shaka-video-container:-webkit-full-screen {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.shaka-video-container:-webkit-full-screen .shaka-text-container {
  font-size: 4.4vmin;
}

.shaka-video-container:-moz-full-screen {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.shaka-video-container:-moz-full-screen .shaka-text-container {
  font-size: 4.4vmin;
}

.shaka-video-container:-ms-fullscreen {
  width: 100%;
  height: 100%;
  background-color: #000;
}

.shaka-video-container:-ms-fullscreen .shaka-text-container {
  font-size: 4.4vmin;
}

.shaka-controls-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  inset: 0;
}

.shaka-video-container:not([shaka-controls="true"]) .shaka-controls-container {
  display: none;
}

.shaka-controls-container * {
  flex-shrink: 0;
}

.shaka-controls-container[casting="true"] .shaka-fullscreen-button {
  display: none;
}

.shaka-canvas-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
}

.shaka-bottom-controls {
  width: 96%;
  z-index: 1;
  padding: 0 0 2.5%;
}

.shaka-controls-button-panel {
  min-width: 48px;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  transition: opacity .6s cubic-bezier(.4, 0, .6, 1);
  display: flex;
  overflow: hidden;
}

.shaka-controls-container[casting="true"] .shaka-controls-button-panel, .shaka-controls-container[shown="true"] .shaka-controls-button-panel {
  opacity: 1;
}

.shaka-controls-button-panel > * {
  color: #fff;
  height: 32px;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 1px;
  padding: 0 5px;
  line-height: .5;
}

.shaka-controls-button-panel .shaka-overflow-menu-only {
  display: none;
}

.shaka-play-button-container {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: absolute;
  inset: 0;
}

.shaka-statistics-container {
  min-width: 300px;
  color: #fff;
  z-index: 2;
  opacity: 0;
  background-color: #232323e6;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 14px;
  transition: opacity .6s cubic-bezier(.4, 0, .6, 1);
  position: absolute;
  top: 15px;
  left: 15px;
  overflow-x: hidden;
  overflow-y: auto;
}

.shaka-controls-container[casting="true"] .shaka-statistics-container, .shaka-controls-container[shown="true"] .shaka-statistics-container {
  opacity: 1;
}

.shaka-statistics-container div {
  justify-content: space-between;
  display: flex;
}

.shaka-statistics-container span {
  color: #969696;
}

.shaka-context-menu {
  z-index: 3;
  background-color: #232323e6;
  border-radius: 2px;
  position: absolute;
}

.shaka-context-menu button {
  width: 100%;
  color: #fff;
  cursor: pointer;
  background: none;
  border: 0;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.shaka-context-menu button:hover {
  background-color: #323232e6;
}

.shaka-context-menu label {
  color: #fff;
  cursor: pointer;
  align-items: flex-start;
  padding: 0 20px;
}

.shaka-context-menu .shaka-current-selection-span {
  color: #fff;
  cursor: pointer;
  align-items: flex-start;
}

.shaka-scrim-container {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(to top, #000 0, #0000 15%);
  flex-shrink: 1;
  margin: 0;
  transition: opacity .6s cubic-bezier(.4, 0, .6, 1);
  position: absolute;
  inset: 0;
}

.shaka-controls-container[casting="true"] .shaka-scrim-container, .shaka-controls-container[shown="true"] .shaka-scrim-container {
  opacity: 1;
}

.shaka-text-container {
  pointer-events: none;
  width: 100%;
  min-width: 48px;
  color: #fff;
  font-size: 20px;
  line-height: 1.4;
  transition: bottom .1s cubic-bezier(.4, 0, .6, 1) .5s;
  position: absolute;
  inset: 0;
}

.shaka-text-container span.shaka-text-wrapper {
  background: none;
  display: inline;
}

.shaka-controls-container[shown="true"] ~ .shaka-text-container {
  transition-delay: 0s;
  bottom: 15%;
}

.shaka-spinner-container {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.shaka-video-container:not([shaka-controls="true"]) .shaka-spinner-container {
  display: none;
}

.shaka-spinner {
  box-sizing: border-box;
  width: 0;
  height: 0;
  filter: drop-shadow(0 0 2px #ffffff80);
  margin: 0;
  padding: 7.8%;
  position: relative;
  top: 0;
  left: 0;
}

.shaka-play-button {
  box-sizing: border-box;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: #ffffffe6;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: none;
  border-radius: 50%;
  margin: 0;
  padding: 7.5%;
  transition: opacity .6s cubic-bezier(.4, 0, .6, 1);
  box-shadow: 0 0 20px #0000001a;
}

.shaka-controls-container[casting="true"] .shaka-play-button, .shaka-controls-container[shown="true"] .shaka-play-button {
  opacity: 1;
}

.shaka-play-button[icon="play"] {
  background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M8%205v14l11-7z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E");
}

.shaka-play-button[icon="pause"] {
  background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M6%2019h4V5H6v14zm8-14v14h4V5h-4z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E");
}

.shaka-current-time {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.shaka-current-time[disabled] {
  color: #fff;
  cursor: default;
  background-color: #0000;
}

.shaka-controls-container button:focus, .shaka-controls-container input:focus {
  outline: 1px solid Highlight;
}

.shaka-controls-container button:-moz-focus-inner, .shaka-controls-container input:-moz-focus-outer {
  border: 0;
  outline: 0;
}

.shaka-controls-container:not(.shaka-keyboard-navigation) button:focus, .shaka-controls-container:not(.shaka-keyboard-navigation) input:focus {
  outline: 0;
}

.shaka-range-container {
  height: 4px;
  background: #fff;
  border-radius: 4px;
  margin: 4px 6px;
  position: relative;
  top: 0;
  left: 0;
}

.shaka-volume-bar-container {
  width: 100px;
  padding: 0;
}

.shaka-range-element {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  height: 12px;
  z-index: 1;
  background: none;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: -4px 0 0;
}

.shaka-range-element::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  height: 12px;
  color: #0000;
  background: none;
  border: none;
}

.shaka-range-element::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  background: #fff;
  border: none;
  border-radius: 12px;
}

.shaka-range-element::-moz-range-track {
  width: 100%;
  cursor: pointer;
  height: 12px;
  color: #0000;
  background: none;
  border: none;
}

.shaka-range-element::-moz-range-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  background: #fff;
  border: none;
  border-radius: 12px;
}

.shaka-seek-bar-container {
  opacity: 0;
  transition: opacity .6s cubic-bezier(.4, 0, .6, 1);
}

.shaka-controls-container[casting="true"] .shaka-seek-bar-container, .shaka-controls-container[shown="true"] .shaka-seek-bar-container {
  opacity: 1;
}

.shaka-ad-markers {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
}

.shaka-spinner-svg {
  transform-origin: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  animation: 2s linear infinite rotate;
  position: absolute;
  inset: 0;
}

.shaka-spinner-path {
  stroke: #202124;
  stroke-dasharray: 20 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: 1s ease-in-out infinite dash;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

.shaka-spacer {
  cursor: default;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
}

.shaka-overflow-menu, .shaka-settings-menu {
  white-space: nowrap;
  max-height: 250px;
  min-width: 180px;
  opacity: 0;
  z-index: 2;
  background: #fff;
  border-radius: 2px;
  flex-direction: column;
  transition: opacity .6s cubic-bezier(.4, 0, .6, 1);
  display: flex;
  position: absolute;
  bottom: 30px;
  right: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 1px 9px #0006;
}

.shaka-controls-container[casting="true"] .shaka-overflow-menu, .shaka-controls-container[casting="true"] .shaka-settings-menu, .shaka-controls-container[shown="true"] .shaka-overflow-menu, .shaka-controls-container[shown="true"] .shaka-settings-menu {
  opacity: 1;
}

.shaka-overflow-menu button, .shaka-settings-menu button {
  color: #000;
  min-height: 30px;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  padding: 3.5px 6px;
  font-size: 14px;
  display: flex;
}

.shaka-overflow-menu button:hover, .shaka-settings-menu button:hover {
  background: #e0e0e0;
}

.shaka-overflow-menu button label, .shaka-settings-menu button label {
  cursor: pointer;
}

.shaka-keyboard-navigation .shaka-overflow-menu button:focus, .shaka-keyboard-navigation .shaka-settings-menu button:focus {
  background: #e0e0e0;
}

.shaka-overflow-menu i, .shaka-settings-menu i {
  padding-left: 10px;
  padding-right: 10px;
}

.shaka-overflow-menu.shaka-low-position, .shaka-settings-menu.shaka-low-position {
  bottom: 15px;
}

.shaka-overflow-menu span {
  text-align: left;
}

.shaka-overflow-button-label {
  flex-direction: column;
  display: flex;
  position: relative;
}

.shaka-current-selection-span {
  color: #0000008a;
}

.shaka-settings-menu span {
  margin-left: 54px;
}

.shaka-back-to-overflow-button span {
  margin-left: 0;
}

.shaka-back-to-overflow-button i {
  padding-right: 20px;
}

.shaka-auto-span {
  left: 17px;
}

.shaka-controls-container[ad-active="true"] {
  pointer-events: none;
}

.shaka-controls-container[ad-active="true"] .shaka-bottom-controls {
  pointer-events: auto;
}

.shaka-client-side-ad-container, .shaka-server-side-ad-container {
  position: absolute;
  inset: 0;
}

.shaka-video-container[shaka-controls="true"] .shaka-client-side-ad-container iframe, .shaka-video-container[shaka-controls="true"] .shaka-server-side-ad-container iframe {
  height: 90%;
}

.shaka-server-side-ad-container {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
}

.shaka-server-side-ad-container:not([ad-active="true"]) {
  pointer-events: none;
}

.shaka-ad-controls {
  z-index: 1;
  flex-direction: row;
  padding-bottom: 1%;
  display: flex;
}

.shaka-video-container:not([shaka-controls="true"]) .shaka-ad-controls {
  display: none;
}

.shaka-ad-controls button, .shaka-ad-controls div {
  color: #fff;
  font-size: initial;
}

.shaka-ad-controls div:not(.shaka-skip-ad-counter) {
  margin: 1px;
}

.shaka-ad-counter, .shaka-ad-position {
  text-shadow: 1px 1px 4px #000;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.shaka-ad-controls .shaka-ad-position:not(.shaka-hidden) + .shaka-ad-counter {
  margin-left: .75em;
}

.shaka-skip-ad-container {
  flex-direction: row;
  margin: 0;
  display: flex;
  position: relative;
  right: -2%;
}

.shaka-skip-ad-button {
  cursor: pointer;
  background: #000000b3;
  border: none;
  padding: 5px 15px;
}

.shaka-skip-ad-button:disabled {
  background: #0000004d;
}

.shaka-skip-ad-counter {
  background: #000000b3;
  margin: 0;
  padding: 5px;
}

.shaka-tooltips-on {
  overflow: visible;
}

.shaka-tooltips-on > [class*="shaka-tooltip"] {
  position: relative;
}

.shaka-tooltips-on > [class*="shaka-tooltip"]:active:after, .shaka-tooltips-on > [class*="shaka-tooltip"]:focus-visible:after, .shaka-tooltips-on > [class*="shaka-tooltip"]:hover:after {
  content: attr(aria-label);
  white-space: nowrap;
  color: #fff;
  background: #232323e6;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: Roboto-Regular, Roboto, sans-serif;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  bottom: 37px;
  left: 16px;
  transform: translateX(-50%);
}

.shaka-tooltips-on > .shaka-tooltip-status:active:after, .shaka-tooltips-on > .shaka-tooltip-status:focus-visible:after, .shaka-tooltips-on > .shaka-tooltip-status:hover:after {
  content: attr(aria-label) " (" attr(shaka-status) ")";
}

.shaka-tooltips-on button:first-child:active:after, .shaka-tooltips-on button:first-child:focus-visible:after, .shaka-tooltips-on button:first-child:hover:after {
  left: 0;
  transform: translateX(0);
}

.shaka-tooltips-on button:last-child:active:after, .shaka-tooltips-on button:last-child:focus-visible:after, .shaka-tooltips-on button:last-child:hover:after {
  left: 32px;
  transform: translateX(-100%);
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf") format("truetype");
}

@font-face {
  font-family: Material Icons Round;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialiconsround/v107/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmM.otf") format("opentype");
}

.material-icons-round {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-family: Material Icons Round;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

/*# sourceMappingURL=index.00df75d4.css.map */
